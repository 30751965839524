import { RouteConfig } from "vue-router";

import TestPNotify from "@/views/tests/testPNotify.vue";
import TestCalendar from "@/views/tests/testCalendar.vue";
import TestDraggable from "@/views/tests/testDraggable.vue";
import TestFitImage from "@/views/tests/testFitImage.vue";
import TestDialog from "@/views/tests/testDialog.vue";

const testRouter: Array<RouteConfig> = [
  {
    path: "/test/pnotify",
    name: "test.pnotiffy",
    component: TestPNotify,
  },
  {
    path: "/test/calendar",
    name: "test.calendar",
    component: TestCalendar,
  },
  {
    path: "/test/draggable",
    name: "test.draggable",
    component: TestDraggable,
  },
  {
    path: "/test/fit-image",
    name: "test.fit-image",
    component: TestFitImage,
  },
  {
    path: "/test/dialog",
    name: "test.dialog",
    component: TestDialog,
  },
];

export default testRouter;
