const DATE_TIME_FORMAT = "Y-MM-DD";
const DATE_SLASH_FORMAT = "Y/MM/DD";
const DATE_TIMESTAMP_FORMAT = "Y-MM-DD h:mm:ss";
const DATE_TIMES_SLASH_FORMAT = "Y/MM/DD H:mm";
const DATE_PICKER_FORMAT = "Y-m-d";
const DATE_TIME_CHECKING_FORMAT = "YMMDDHms";
const DATE_MOMENT_FORMAT = "YYYY-MM-DD";
const ROLE_ADMIN = "admin";
const ROLE_CUSTOMER = "customer";
const ROLE_ADMIN_CUSTOMER = "admin-customer";

export {
  DATE_TIME_FORMAT,
  ROLE_ADMIN,
  ROLE_CUSTOMER,
  ROLE_ADMIN_CUSTOMER,
  DATE_PICKER_FORMAT,
  DATE_TIMESTAMP_FORMAT,
  DATE_TIME_CHECKING_FORMAT,
  DATE_MOMENT_FORMAT,
  DATE_TIMES_SLASH_FORMAT,
  DATE_SLASH_FORMAT,
};
