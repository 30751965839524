




import {Vue, Component} from "vue-property-decorator";
import Home from "@/components/homeComponent.vue";

@Component({
  name: "CustomerHome",
  components: {
    Home
  },
})
export default class CustomerHome extends Vue {}
