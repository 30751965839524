






















































































































































































































































































































































































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import Estimate, {
  KUBUN_NAMES,
  BUSINESS_CATEGORY_NAMES,
  TAX_NAMES,
  ANNUAL_SALE_NAMES,
  ADVISORS,
  ADVISOR_NAMES,
  STATUS_NAMES,
  INCOME_COST_TITLE,
  BUSINESS_CATEGORY_AMOUNTS,
  TAX_AMOUNTS,
  ANNUAL_SALE_AMOUNTS,
  ANNUAL_SALE_FEES,
  TAX_COSTS,
  TAXS,
  ANNUAL_SALES,
  KUBUN
} from "@/model/estimate";
import estimateApi from "@/api/estimateApi";
import QErrors from "@/common/qErrors.vue";
import PostalCode from "@/components/common/postalCode.vue";
import DEstimate, {CONTRACT_STATUSES, CONTRACT_STATUS_NAMES} from "@/model/dEstimate";
import numeral from 'numeral';
import _ from 'lodash';
import moment from 'moment';
import DOriginal from "@/model/dOriginal";
import mtgCreateOrUpdate from "@/components/admin/estimate/modals/mtgCreateOrUpdate.vue";
import estimateModal from "@/components/admin/estimate/modals/estimate.vue";
import contractModal from "@/components/admin/estimate/modals/contract.vue";

@Component({
  name: "Admin",
  components: {
    QErrors,
    PostalCode,
    mtgCreateOrUpdate,
    estimateModal,
    contractModal
  },
})
export default class EstimateDetail extends Vue {
  public estimate = new Estimate();
  public dOriginals = [new DOriginal()];
  public kubunNames = KUBUN_NAMES;
  public taxNames = TAX_NAMES;
  public businessCategoryNames = BUSINESS_CATEGORY_NAMES;
  public annualSaleNames = ANNUAL_SALE_NAMES;
  public advisors = ADVISORS;
  public advisorNames = ADVISOR_NAMES;
  public statusNames = STATUS_NAMES;
  public contractStatuses = CONTRACT_STATUSES;
  public contractStatusNames = CONTRACT_STATUS_NAMES;
  public errors = {};
  public totals = {
    month_cost: 0,
    year_cost: 0,
    title_income_cost: INCOME_COST_TITLE[this.estimate.kubun],
    income_cost: 0,
    tax_cost: 0,
    total_cost: 0,
  };
  public dEstimate = new DEstimate();
  public isInit = true;
  public formatPrice = '0,0';
  public dateContracts = {
    years: {},
    months: {},
    days: {}
  }
  public isCustomerModalCancel = false;
  public isEstimateModalCancel = false;

  @Watch('estimate.kubun', {deep: true})
  changeEstimate() {
    if (!this.isInit) {
      this.estimate.annual_sales = -1;
    } else {
      this.isInit = false;
    }
    this.totals.title_income_cost = INCOME_COST_TITLE[this.estimate.kubun];
  }

  @Watch('estimate.business_category', {deep: true})
  changeBusinessCategory() {
    this.getTotals();
  }

  @Watch('estimate.annual_sales', {deep: true})
  changeAnnualSales() {
    this.getTotals();
  }

  @Watch('estimate.tax', {deep: true})
  changeTax() {
    this.getTotals();
  }

  @Watch('estimate.d_estimate', {deep: true})
  changeGensenQuatity() {
    const nenchoQuantity = this.estimate.d_estimate[0].nencho_quantity;
    let nenchoTotal = 0;
    if (nenchoQuantity == 1) {
      nenchoTotal = 20000;
    }

    if (nenchoQuantity > 1) {
      nenchoTotal = 30000;
    }
    if (nenchoQuantity > 5) {
      nenchoTotal += (nenchoQuantity - 5) * 5000
    }

    const gensenTotal = this.estimate.d_estimate[0].gensen_quantity * this.estimate.d_estimate[0].gensen_price;
    const shokyakuTotal = this.estimate.d_estimate[0].shokyaku_quantity * this.estimate.d_estimate[0].shokyaku_price;
    const todokeTotal = this.estimate.d_estimate[0].todoke_quantity * this.estimate.d_estimate[0].todoke_price;
    const kabukaTotal = this.estimate.d_estimate[0].kabuka_quantity * this.estimate.d_estimate[0].kabuka_price;

    this.estimate.d_estimate[0].nencho_total = nenchoTotal;
    this.estimate.d_estimate[0].gensen_total = gensenTotal;
    this.estimate.d_estimate[0].shokyaku_total = shokyakuTotal;
    this.estimate.d_estimate[0].todoke_total = todokeTotal;
    this.estimate.d_estimate[0].kabuka_total = kabukaTotal;

    this.sumTotal();
  }

  @Watch('estimate.d_original', {deep: true})
  changeItem() {
    if (this.estimate.d_original.length > 0) {
      for (let index in this.estimate.d_original) {
        const quantity = this.estimate.d_original[index].quantity || 0;
        const unitPrice = this.estimate.d_original[index].unit_price || 0;
        this.estimate.d_original[index].total = quantity * unitPrice;
      }
      this.sumTotal();
    }
  }

  sumTotal() {
    const dEstimate = this.estimate.d_estimate[0];
    let total = (+dEstimate.year_cost) + (+dEstimate.tax_cost) + (+dEstimate.income_cost)
      + dEstimate.nencho_total + dEstimate.gensen_total + dEstimate.shokyaku_total + dEstimate.todoke_total
      + dEstimate.kabuka_total;
    _.map(this.estimate.d_original, function(line) {
      total+= line.total
    });

    dEstimate.total = total;
    dEstimate.consumption_tax = total / 10;
    dEstimate.total_all = dEstimate.total + dEstimate.consumption_tax
  }

  get estimateId() {
    return this.$router.currentRoute.params.id;
  }

  mounted() {
    this.getDetail();
    const currentYear = Number.parseInt(moment().format('Y'));
    for (let year = currentYear; year <= currentYear + 1; year++) {
      this.dateContracts.years[year] = year;
    }

    for (let month = 1; month <= 12; month++) {
      this.dateContracts.months[month] = month;
    }

    for (let day = 1; day <= 31; day++) {
      this.dateContracts.days[day] = day;
    }
  }

  async getDetail() {
    this.isInit = true;
    const result = await estimateApi.detail(this.estimateId);
    this.estimate = result.data;

    if (this.estimate.d_original.length === 0) {
      this.estimate.d_original = [];
    }
  }

  async updateCustomer() {
    const response = await estimateApi.updateCustomer(this.estimateId, this.estimate);
    if (!response.data.status) {
      this.errors = response.data.message;
    } else {
      this.errors = {};
      await this.getDetail();
      this.$notify({
        group: 'notify',
        text: response.data.message,
        type: 'success',
      });
    }
  }

  public onChangePostalCode(address) {
    this.estimate.address_1 = address.pref_name + " " + address.city_name;
  }

  public addLine() {
    const dOriginal = new DOriginal();
    this.estimate.d_original.splice(this.estimate.d_original.length, 0, dOriginal);
  }

  public deleteLine(index) {
    this.estimate.d_original.splice(index, 1);
  }

  async updateEstimate() {
    const response = await estimateApi.updateEstimate(this.estimateId, this.estimate);
    if (!response.data.status) {
      this.errors = response.data.message;
    } else {
      this.errors = {};
      this.getDetail();
      this.$notify({
        group: 'notify',
        text: response.data.message,
        type: 'success',
      });
    }
  }

  public getTotals() {
    const formatPrice = "0,0";
    let businessCateAmount = BUSINESS_CATEGORY_AMOUNTS[this.estimate.business_category] || 0;
    if (this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_5000M_AND_10000M
      || this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_10000M_AND_50000M
      || this.estimate.annual_sales == ANNUAL_SALES.OVER_50000M) {
      businessCateAmount = 1;
    }

    let taxAmount = TAX_AMOUNTS[this.estimate.tax];
    if (this.estimate.kubun == KUBUN.CORPORATION
      && (this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_5000M_AND_10000M
        || this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_10000M_AND_50000M
        || this.estimate.annual_sales == ANNUAL_SALES.OVER_50000M)) {
      taxAmount = 0;
    }
    const annualAmounts = ANNUAL_SALE_AMOUNTS[this.estimate.kubun];
    const annualAmount = annualAmounts[this.estimate.annual_sales] || 0;
    let monthCostFormula = annualAmount * (businessCateAmount + taxAmount);
    let yearCost = monthCostFormula * 12;

    const annualSaleFees = ANNUAL_SALE_FEES[this.estimate.kubun];
    let annualSaleFee = annualSaleFees[this.estimate.annual_sales] || 0;

    const taxCosts = TAX_COSTS[this.estimate.kubun];
    let taxCost = taxCosts[this.estimate.annual_sales];
    if (this.estimate.tax == TAXS.FREE) {
      taxCost = 0;
    }

    let formulaTotal = yearCost + annualSaleFee + taxCost;

    if (this.estimate.business_category == -1) {
      monthCostFormula = 0;
      yearCost = 0;
      taxCost = 0;
      annualSaleFee = 0;
      formulaTotal = 0;
    }

    this.totals.month_cost = numeral(monthCostFormula).format(formatPrice);
    this.totals.year_cost = numeral(yearCost).format(formatPrice);
    this.totals.income_cost = numeral(annualSaleFee).format(formatPrice);
    this.totals.tax_cost = numeral(taxCost).format(formatPrice);
    this.totals.total_cost = numeral(formulaTotal).format(formatPrice);

    const dEstimate = this.estimate.d_estimate[0];
    dEstimate.month_cost = monthCostFormula;
    dEstimate.year_cost = monthCostFormula * 12;
    dEstimate.tax_cost = taxCost;
    dEstimate.income_cost = annualSaleFee;

    this.sumTotal();
  }

  showCancelCustomerModal() {
    this.isCustomerModalCancel = true;
  }

  isYesCancelCustomerModal() {
    this.isCustomerModalCancel = false;
    this.getDetail();
  }

  isNoCancelCustomerModal() {
    this.isCustomerModalCancel = false;
  }

  showCancelEstimateModal() {
    this.isEstimateModalCancel = true;
  }

  isYesCancelEstimateModal() {
    this.isEstimateModalCancel = false;
    this.getDetail();
  }

  isNoCancelEstimateModal() {
    this.isEstimateModalCancel = false;
  }

  showMtgModal(mtgEstimate = null) {
    (this.$refs.estimateMtg as mtgCreateOrUpdate).show(this.estimateId, mtgEstimate);
  }

  showEstimateModal() {
    (this.$refs.estimateModal as estimateModal).show(this.estimateId);
  }

  showContractModal() {
    (this.$refs.contractModal as contractModal).show(this.estimateId);
  }
}
