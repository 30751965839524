































































import QPaginate from "@/common/qPaginate.vue";
import QSearch from "@/common/qSearch.vue";
import QTableHeader from "@/common/qTableHeader.vue";
import PaginateModel from "@/model/paginate";
import { Component, Vue } from "vue-property-decorator";
import Contact from "@/model/contact";
import contactApi from "@/api/contactApi";

@Component({
  name: "Admin",
  components: {
    QSearch,
    QPaginate,
    QTableHeader,
  },
})
export default class ContactList extends Vue {
  public contacts: Contact[] = null;
  public keyword: string = null;
  public search = this.searchInital();

  public headers = [
    { id: "created", name: "日時",order: "desc" },
    { id: "kubun", name: "区分" },
    { id: "company_name", name: "会社名" },
    { id: "customer_name", name: "お客様名" },
    { id: "mail_address", name: "メールアドレス" },
    { id: "tel", name: "電話番号" },
    { id: "contact_detail", name: "お問い合わせ内容" },
    { id: "", name: "" },
  ];

  public pagination = new PaginateModel({
    page: 0,
    pageCount: 0,
    start: 0,
    end: 0,
    count: 0,
  });

  public searchInital() {
    return {
      keyword: null,
      sort: "created",
      direction: "desc",
      page: "1",
    };
  }

  get getPaginate() {
    return this.pagination;
  }

  mounted() {
    this.initalSearch(null);
    this.getContactList();
  }

  public clear() {
    this.search = this.searchInital();
    this.changeState();
    this.getContactList();
  }

  public doSearch(query: any): void {
    this.search = { ...this.search, ...query };
    this.getContactList();
  }

  public onSearch(): void {
    this.changeState();
    this.getContactList();
  }

  public changeState(): void {
    let { name, params, query } = this.$router.currentRoute;

    this.search.page = "1";
    query = { ...query, ...this.search };

    this.$router
      .replace({ name: name, params: params, query })
      .catch((error) => {
        console.error(error);
      });
  }

  public initalSearch(parameters: any): void {
    const query = parameters || this.$router.currentRoute.query;
    this.search = {
      ...this.search,
      ...query,
    };
  }

  public async getContactList() {
    const result = await contactApi.list(this.search);
    this.pagination = result.paginate;
    this.contacts = result.list;
  }
}
