




import { Vue, Component } from "vue-property-decorator";
import EstimateDetail from "@/components/admin/estimate/estimateDetail.vue";

@Component({
  name: "AdminEstimateDetail",
  components: {
    EstimateDetail,
  },
})
export default class AdminEstimateDetail extends Vue {}
