
















import { Component, Vue } from "vue-property-decorator";
import userApi from "@/api/userApi";
@Component({
  name: "QSiderbar",
})
export default class QSiderbar extends Vue {
  public logout() {
    try {
      userApi.logout().then(() => {
        window.location.href = '/login';
      });
    } catch (error) {
      console.log(error);
    }
  }
}
