export default {
  customer_page: {
    message: {
      created: "Created",
      updated: "Updated",
      deleted: "Deleted",
    },
  },
  cart_page: {
    messsage: {
      remove_cart_item: "商品をカートから削除します。",
      removed_cart_item: "削除しました。",
      empty_cart: "カートに商品を追加してください。",
      remove_cart: "Are you sure",
      removed_cart: "The cart has been deleted!",
      cart_notfound: "The cart not found",
    },
  },
  cart_delivery_page: {
    messsage: {
      prodction_was_deleted: "Have a production was deleted!",
      has_errors: "Please check again",
      empty_delivery_address: "Please add delivery address",
    },
  },
  cancel: "変更内容を破棄して編集を終了します。この操作は取り消せません。",
  delete: "削除を実行します。この操作は取り消せません。",
};
