




import { Vue, Component } from "vue-property-decorator";

import EstimateAdd from "@/components/customer/estimate/estimateAdd.vue";

@Component({
  name: "CustomerEstimateAdd",
  components: {
    EstimateAdd,
  },
})
export default class CustomerEstimateAdd extends Vue {}
