























import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  name: "qTableHeader",
  components: {},
})
export default class QTableHeader extends Vue {
  @Prop() headers: any[];

  public mounted(): void {
    const { sort, direction } = this.$router.currentRoute.query;
    this.headers.every((header) => {
      if (header.id === sort) {
        header.order = direction;
        this.clear(header.id);
        return false;
      }
      return true;
    });
  }

  public clear(excludeId: string) {
    this.headers.map((header) => {
      if ("order" in header && header.id !== excludeId) {
        header.order = "";
      }
      return header;
    });
  }

  public sort(header): void {
    if ("order" in header) {
      if (header.order === "") {
        header.order = "desc";
      } else {
        header.order = header.order === "desc" ? "asc" : "desc";
      }
      this.clear(header.id);

      let { name, params, query } = this.$router.currentRoute;

      query = { ...query, ...{ sort: header.id, direction: header.order } };

      this.$emit("sort", query);

      this.$router
        .replace({ name: name, params: params, query })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
