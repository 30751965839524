






























































































































































import QPaginate from "@/common/qPaginate.vue";
import QSearch from "@/common/qSearch.vue";
import QTableHeader from "@/common/qTableHeader.vue";
import { Component, Vue } from "vue-property-decorator";
import Contact from "@/model/contact";
import DCMtg from "@/model/dCMtg";
import contactApi from "@/api/contactApi";
import contactHistory from "@/components/admin/contact/contactHistory.vue";

@Component({
  name: "ContactDetail",
  components: {
    QSearch,
    QPaginate,
    QTableHeader,
    contactHistory,
  },
})
export default class ContactDetail extends Vue {
  public contact = new Contact();
  get contactId() {
    return this.$router.currentRoute.params.id;
  }

  mounted() {
    this.detail();
  }

  get histories() {
    let histories = this.contact.d_c_mtg;
    return histories ? histories.map((item) => new DCMtg(item)) : [];
  }

  public async detail() {
    const result = await contactApi.detail(this.contactId);
    this.contact = new Contact(result.data)

  }

  public showHistory(history = null) {
    (this.$refs.contactHistory as contactHistory).show(this.contact.id, history);
  }
  public refresh(contact) {
    this.contact = contact;
  }

  public back() {
    history.back();
  }
}
