
















import { Vue, Component } from "vue-property-decorator";
@Component({
  name: "TestFitImage",
  components: {},
})
export default class TestFitImage extends Vue {
  currentDate = "2020-10-16";
  public config = {};
}
