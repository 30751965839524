import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import Notifications from "vue-notification";
import QModal from "@/common/plugins/modal";
import messages from "@/i18n";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
Vue.use(Notifications);
Vue.use(QModal);

Vue.config.productionTip = false;
const trans18next = new VueI18n({
  locale: "ja",
  messages,
});

const vueApp = new Vue({
  router,
  i18n: trans18next,
  store,
  render: (h) => h(App),
}).$mount("#app");

export default vueApp;
