






import { Vue, Component } from "vue-property-decorator";
import click2Top from "@/common/directives/click2Top";

@Component({
  name: "QFooter",
  directives: {
    click2Top,
  },
})
export default class QFooter extends Vue {}
