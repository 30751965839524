








































import User from "@/model/user";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

const authStore = namespace("Auth");
@Component({
  name: "QCustomerNav",
})
export default class QCustomerNav extends Vue {
  @authStore.Getter
  getUserLogging!: User;
  isActive = false;

  get totalCartItem(): boolean {
    return this.$store.state.Cart.items.length;
  }

  get customerName(): string {
    // 〇〇会社 東京営業所 山田太郎
    // Display the customer name and login user name.
    let user: User = this.$store.state.Auth.userLogging;
    if (user) {
      return user.customer.customer_name + " " + user.username;
    }
    return "";
  }

  openNavLists(hash = '') {
    if (location.hash == hash) {
      return;
    }
    this.$router.push({ name: 'customer.home', hash: hash })
    this.isActive = !this.isActive;
  }
}
