export const CONTRACT_STATUSES = {
  SPECIFY: 0,
  BLANK: 1,
};

export const CONTRACT_STATUS_NAMES = {
  [CONTRACT_STATUSES.SPECIFY]: '指定',
  [CONTRACT_STATUSES.BLANK]: '空欄にする',
};

export interface IDEstimate {
  estimate_detail_id?: string;
  estimate_id?: string;
  estimate_no?: string;
  month_cost: number;
  year_cost: number;
  settle_cost?: number;
  tax_cost: number;
  income_cost: number;
  nencho_quantity?: number;
  nencho_total?: number;
  gensen_quantity?: number;
  gensen_unitt?: string;
  gensen_price?: number;
  gensen_total?: number;
  shokyaku_quantity?: number;
  shokyaku_unit?: string;
  shokyaku_price?: number;
  shokyaku_total?: number;
  todoke_quantity?: number;
  todoke_unit?: string;
  todoke_price?: number;
  todoke_total?: number;
  kabuka_quantity?: number;
  kabuka_unit?: string;
  kabuka_price?: number;
  kabuka_total?: number;
  remark?: string;
  start_date?: string;
  end_date?: string;
  start_day_contract?: number;
  start_month_contract?: number;
  start_year_contract?: number;
  end_contract_status?: number;
  end_day_contract?: number;
  end_month_contract?: number;
  end_year_contract?: number;
  total?: number;
  consumption_tax?: number;
  total_all?: number;
  created?: string;
  modified?: string;
  deleted?: string;
}

export class DEstimateDTO implements IDEstimate {
  estimate_detail_id?: string;
  estimate_id?: string;
  estimate_no?: string;
  month_cost: number;
  year_cost: number;
  settle_cost?: number;
  tax_cost: number;
  income_cost: number;
  nencho_quantity?: number;
  nencho_total?: number;
  gensen_quantity?: number;
  gensen_unitt?: string;
  gensen_price?: number;
  gensen_total?: number;
  shokyaku_quantity?: number;
  shokyaku_unit?: string;
  shokyaku_price?: number;
  shokyaku_total?: number;
  todoke_quantity?: number;
  todoke_unit?: string;
  todoke_price?: number;
  todoke_total?: number;
  kabuka_quantity?: number;
  kabuka_unit?: string;
  kabuka_price?: number;
  kabuka_total?: number;
  remark?: string;
  start_date_contract?: string;
  end_date_contract?: string;
  start_day_contract?: number;
  start_month_contract?: number;
  start_year_contract?: number;
  end_contract_status?: number;
  end_day_contract?: number;
  end_month_contract?: number;
  end_year_contract?: number;
  total?: number;
  consumption_tax?: number;
  total_all?: number;
  created?: string;
  modified?: string;
  deleted?: string;
}

export default class DEstimate extends DEstimateDTO {

  constructor(dto?: DEstimateDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    Object.assign(this, {
      estimate_id: "",
      estimate_no: "",
      month_cost: 0,
      year_cost: 0,
      income_cost: 0,
      nencho_total: 0,
      settle_cost: 0,
      gensen_unit: '',
      gensen_price: 1000,
      gensen_total: 0,
      shokyaku_unit: '',
      shokyaku_price: 10000,
      shokyaku_total: 0,
      todoke_unit: '',
      todoke_price: 10000,
      todoke_total: 0,
      kabuka_unit: '',
      kabuka_price: 100000,
      kabuka_total: 0,
      remark: '',
      start_date_contract: '',
      end_date_contract_status: CONTRACT_STATUSES.SPECIFY,
      end_date_contract: ''
    });
  }
}
