






















// @ is an alias to /src
import draggable from "vuedraggable";

import { Vue, Component } from "vue-property-decorator";
@Component({
  name: "testDgraggable",
  components: {
    draggable,
  },
})
export default class TestDgraggable extends Vue {
  dragging = false;
  enabled = true;
  listDragg = [
    { name: "John 1", id: 0 },
    { name: "Joao 2", id: 1 },
    { name: "Jean 3", id: 2 },
  ];

  checkMove(event) {
    console.log("Future index: " + event.draggedContext.futureIndex);
  }
}
