import PaginateModel from "@/model/paginate";
import Estimate, { EstimateDTO } from "@/model/estimate";
import { AxiosResponse } from "axios";
import api from "./api";
import _ from "lodash";
import dEMtg from "@/model/dEMtg";

interface RequestInterface {
  data: EstimateDTO[];
  paginate: any;
}

interface EstimateResponse {
  success: string;
  data: any;
}
interface EstimateList {
  list: Estimate[];
  paginate: any;
}

class EstimateApi extends api {
  constructor() {
    super();
    this.setup();
  }

  async list(query: any): Promise<EstimateList> {
    const response = await this.getHttp().get<RequestInterface>(
      "/estimate/list",
      {
        params: query,
      }
    );

    const list: Estimate[] = [];
    response.data.data.map((EstimateDto) =>
      list.push(new Estimate(EstimateDto))
    );
    const paginate = new PaginateModel(response.data.paginate);
    return {
      list: list,
      paginate: paginate,
    };
  }

  async detail(id: string): Promise<EstimateResponse> {
    const response = await this.getHttp().get<any>(
      "/estimate/detail/" + id
    );

    if (response.status === 200) {
      return response.data;
    }
  }

  async add(data: Estimate): Promise<AxiosResponse> {
    return this.getHttp().post<EstimateResponse>("/estimate/create", data);
  }

  async updateCustomer(estimateId, data: Estimate): Promise<AxiosResponse> {
    return this.getHttp().put<EstimateResponse>("/estimate/update-customer/" + estimateId, data);
  }

  async updateEstimate(estimateId, data: Estimate): Promise<AxiosResponse> {
    const dEstimate = data.d_estimate[0];
    const startYearContract = !_.isNil(dEstimate.start_year_contract)
      && dEstimate.start_year_contract.toString().length > 0 ? dEstimate.start_year_contract : '';
    const startMonthContract = !_.isNil(dEstimate.start_month_contract)
      && dEstimate.start_month_contract.toString().length > 0 ? '-' + dEstimate.start_month_contract : '';
    const startDayContract = !_.isNil(dEstimate.start_day_contract)
      && dEstimate.start_day_contract.toString().length > 0 ? '-' + dEstimate.start_day_contract : '';

    const endYearContract = !_.isNil(dEstimate.end_year_contract)
      && dEstimate.end_year_contract.toString().length > 0 ? dEstimate.end_year_contract : '';
    const endMonthContract = !_.isNil(dEstimate.end_month_contract)
      && dEstimate.end_month_contract.toString().length > 0 ? '-' + dEstimate.end_month_contract : '';
    const endDayContract = !_.isNil(dEstimate.end_day_contract)
      && dEstimate.end_day_contract.toString().length > 0 ? '-' + dEstimate.end_day_contract : '';

    dEstimate.start_date_contract = startYearContract + startMonthContract + startDayContract;
    dEstimate.end_date_contract = endYearContract + endMonthContract + endDayContract;
    data.d_estimate[0] = dEstimate;
    return this.getHttp().put<EstimateResponse>("/estimate/update-estimate/" + estimateId, data);
  }

  async createOrUpdateMtg(estimateId = '', data: dEMtg): Promise<AxiosResponse> {
    return this.getHttp().post<EstimateResponse>("/estimate/update-mtg/" + estimateId, data);
  }

  async deleteMtg(mtgId): Promise<AxiosResponse> {
    return this.getHttp().delete<EstimateResponse>("/estimate/delete-mtg/" + mtgId);
  }

  async downloadEstimate(estimateId = '', data: dEMtg): Promise<AxiosResponse> {
    return this.getHttp().post<EstimateResponse>("/estimate/download-estimate/" + estimateId, data);
  }
}
export default new EstimateApi();
