import Axios, {AxiosInstance, AxiosResponse} from "axios";
import router from "@/router";
import store from "@/store";
import Vue from "vue";
import Estimate from "@/model/estimate";

class api {
  public http: any;
  public loader: any;
  public baseURL: any;
  getAuthenticateStatus(): string | null {
    return store.state.Auth.authenticate_status || "";
  }
  getTokenExpiredTime(): string | null {
    return store.state.Auth.expired_at || "";
  }

  getHttp(): AxiosInstance {
    return this.http;
  }
  setup(): void {
    const token = document
      .querySelector('meta[name="token"]')
      .getAttribute("content");
    // set basic url or toket
    const config = {
      headers: {
        "Content-Type": "application/json",

        // disable IE11 GET cache
        // @see https://stackoverflow.com/questions/37755782/prevent-ie11-caching-get-call-in-angular-2
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        "X-CSRF-Token": token,
      },
      baseURL: process.env.VUE_APP_API_URL,
    };
    this.baseURL = process.env.VUE_APP_API_URL;
    this.http = Axios.create(config);
    this.http.interceptors.request.use(
      (config: any) => {
        console.log(this.loader);
        this.loader && this.loader.hide();
        if (!(config.data && config.data.notLoading)) {
          this.loader = Vue.$loading.show({});
        }

        return config;
      },
      (error: any) => {
        this.loader && this.loader.hide();
        // Do something with request error
        return Promise.reject(error);
      }
    );
    this.http.interceptors.response.use(
      (response: any) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        this.loader && this.loader.hide();
        return response;
      },
      (error: any) => {
        this.loader && this.loader.hide();
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (
          error.response.status === 401 &&
          router.currentRoute.name !== "login"
        ) {
          // AuthModule.logout();
          window.location.href = "/login";
          Promise.resolve();
        } else {
          return Promise.reject(error);
        }
      }
    );
  }
}

export default api;
