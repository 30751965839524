import Vue from "vue";

class QNotice {
  public success(data: any) {
    return Vue.notify({
      group: "admin",
      type: "brighttheme-success",
      classes: ["pnotify"],
      ...data,
    });
  }
  public error(data: any) {
    return Vue.notify({
      group: "admin",
      type: "brighttheme-error",
      ...data,
    });
  }
}

export default new QNotice();
