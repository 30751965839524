// we need our modal component
import * as objectFitImages from "object-fit-images";
import AppModal from "@/common/qModal.vue";
import $ from "jquery";

const Modal = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  EventBus: null,
  install(Vue) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emiting events from our main instance
    // this EventBus will be available as Modal.EventBus
    this.EventBus = new Vue();

    // making our modal component global
    Vue.component("QModal", AppModal);
    // Register a global custom directive called `v-focus`
    Vue.directive("image-fit", {
      // When the bound element is inserted into the DOM...
      inserted: (element: any) => {
        // Focus the element
        objectFitImages(element);
      },
      update: (element: any) => {
        // Focus the element
        objectFitImages(element);
      },
    });
    Vue.directive("js-togglebtn", {
      // When the bound element is inserted into the DOM...
      inserted: (element: any) => {
        // Focus the element
        $(element).click(() => {
          $(element).next().slideToggle();
          $(element).toggleClass("open");
        });
      },
    });

    Vue.directive("error-state", {
      // When the bound element is inserted into the DOM...
      componentUpdated: (element: any, binding) => {
        // Focus the element
        const errors = binding.value[0];
        const key = binding.value[1];
        if (errors && errors[key]) {
          element.setAttribute("status", "error");
        } else {
          element.setAttribute("status", "");
        }
      },
    });

    Vue.filter("number", function (value) {
      if (value === 0) return 0;
      else if (!value) return "";
      return Intl.NumberFormat().format(Math.ceil(value));
    });
    // exposing global $modal object with method show()
    // method show() takes object params as argument
    // inside this object we can have modal title, text, styles... and also our callback confirm function
    Vue.prototype.$modal = {
      showDelete: (parameters) => {
        // if we use this.$modal.show(parameters) inside our original Vue instance
        // we will emit 'show' event with parameters 'parameters'
        parameters = parameters || {};
        parameters.type = "error";
        this.EventBus.$emit("show", parameters);
      },
      showCancel: (parameters) => {
        // if we use this.$modal.show(parameters) inside our original Vue instance
        // we will emit 'show' event with parameters 'parameters'
        parameters = parameters || {};
        parameters.type = "info";
        parameters.confirmText = parameters.confirmText ?? "はい";
        this.EventBus.$emit("show", parameters);
      },
      show: (parameters) => {
        this.EventBus.$emit("show", parameters);
      },
    };
  },
};
declare interface VModal {
  showDelete(parameters?: any): void;
  showCancel(parameters?: any): void;
  show(parameters?: any): void;
}
declare module "vue/types/vue" {
  interface Vue {
    $modal: VModal;
  }
}
export default Modal;
