


















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import ClickOutside from './directives/vClickOutside';

@Component({
  name: "QMultiSelect",
  directives: {
    ClickOutside
  },
})
export default class QMultiSelect extends Vue {
  @Prop()
  options: any;
  @Prop()
  title: string;
  @Prop()
  value: [];
  @Prop()
  nameId: string;

  show = false;
  selected = [];

  get selectedText() {
    let selectedText = "";

    this.selected.map(function (value) {
      selectedText = selectedText + this.options[value] + '、';
    }.bind(this));

    return selectedText.slice(0, -1);
  }

  @Watch('selected', { deep: true})
  changeSelected(value) {
    this.$emit('checked', value);
  }

  @Watch('value', { deep: true})
  changeValue(value) {
    this.selected = value;
  }

  showDropdown() {
    this.show = !this.show
  }

  hide() {
    this.show = false;
  }

}
