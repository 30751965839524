import userApi from "@/api/userApi";
import Customer from "./customer";
import store from "@/store";
import router from "@/router";

export interface IUser {
  id?: number;
  username: string;
  is_enabled?: boolean;
  created?: string;
  customer?: Customer;
  email?: string;
  password?: string;
}

export class UserDTO implements IUser {
  id?: number;
  username = "";
  name = "";
  department_name = "";
  is_enabled? = false;
  created? = "";
  customer?: Customer;
  email? = "";
  password? = "";
}

export default class User extends UserDTO {
  original = {};
  constructor(dto?: UserDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
      dto.customer && (dto.customer = new Customer(dto.customer));
    } else {
      this.new();
    }
  }

  get statusName(): string {
    return this.is_enabled ? "on" : "off";
  }

  public new() {
    Object.assign(this, {
      id: null,
      username: "",
      email: "",
      password: "",
      is_enabled: "",
      customer: new Customer({
        id: null,
        customer_code: "",
        customer_name: "",
        representative_name: "",
        industry_code: "",
      }),
    });
  }

  public logout() {
    return new Promise(() => {
      userApi.logout().then((response) => {
        store.dispatch(
          "Auth/updateAuthenticateStatus",
          response.data.data.authenticate_status
        );
        router.push({ name: "login" });
      });
    });
  }
}

export class UserCustomer extends User {}
