import VueScrollTo from "vue-scrollto";

export default {
  bind: (element: HTMLElement) => {
    element.addEventListener("click", () => {
      scroll();
    });
  },
};

const scroll = () => {
  VueScrollTo.scrollTo({ force: true });
};
