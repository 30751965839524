




import { Vue, Component } from "vue-property-decorator";

import ContactList from "@/components/admin/contact/contactList.vue";

@Component({
  name: "AdminContact",
  components: {
    ContactList,
  },
})
export default class AdminContactList extends Vue {}
