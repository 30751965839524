
































































































import store from "@/store";
import UserApi from "@/api/userApi";

export default {
  created(): void {
    let interval = setInterval(refreshToken, 60000);

    function refreshToken() {
      let currentDate = new Date();
      let currentTime = currentDate.getTime() / 1000;
      let expired_at = store.state.Auth.expired_at;
      let remain = (expired_at - currentTime) / 60;

      if (remain < 10 * 24 * 60 && remain > 0) {
        try {
          UserApi.refreshToken().then((response) => {
            store.dispatch(
              "Auth/updateAuthenticateStatus",
              response.data.data.authenticate_status
            );
            store.dispatch(
              "Auth/updateExpiredAt",
              response.data.data.expired_at
            );
            clearInterval(interval);
          });
        } catch {
          // console.log(error);
        }
      }
    }
    // store.dispatch("Auth/fetchCurrentUser");
  },
};
