





























// we must import our Modal plugin instance
// because it contains reference to our Eventbus
import Modal from "@/common/plugins/modal";
import { Component, Vue } from "vue-property-decorator";
@Component({
  name: "qModal",
})
export default class QModal extends Vue {
  visible = false;
  text = "";
  confirmText = "削除";
  confirmClass = "mb__add-color-red";
  // adding callback function variable
  onConfirm = {};
  onClose = {};
  type = "";
  title = "";

  titleMap = {
    // delete
    error: "削除",

    // cancel
    info: "変更内容破棄の確認",
  };

  hide() {
    // this method is unchanged
    this.visible = false;
  }
  confirm() {
    // we must check if this.onConfirm is function
    if (typeof this.onConfirm === "function") {
      // run passed function and then close the modal
      this.onConfirm();
      this.hide();
    } else {
      // we only close the modal
      this.hide();
    }
  }
  close() {
    // we must check if this.onConfirm is function
    if (this.onClose && typeof this.onClose === "function") {
      // run passed function and then close the modal
      this.onClose();
      this.hide();
    } else {
      // we only close the modal
      this.hide();
    }
  }
  show(parameters) {
    // making modal visible
    this.visible = true;
    // setting title and text
    this.text = parameters.text;
    this.confirmText = parameters.confirmText ? parameters.confirmText : "削除";
    this.confirmClass = parameters.confirmText
      ? "mb__add-color-blue"
      : this.confirmClass;
    // setting callback function
    this.onConfirm = parameters.onConfirm;
    this.onClose = parameters.onClose;
    this.type = parameters.type;
    console.log(parameters);
    this.title = this.titleMap[this.type] || "";
  }
  beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Modal.EventBus.$on("show", (parameters) => {
      this.show(parameters);
    });
  }
}
