




import { Vue, Component } from "vue-property-decorator";
import ContactDetail from "@/components/admin/contact/contactDetail.vue";

@Component({
  name: "AdminContactDetail",
  components: {
    ContactDetail,
  },
})
export default class AdminContactDetail extends Vue {}
