


























import { Vue, Component } from "vue-property-decorator";
import Loading from "vue-loading-overlay";
import QCustomerNav from "./qNav.vue";
import QCustomerFooter from "./qFooter.vue";
import HomeComponent from "@/components/homeComponent.vue";
import click2Top from "@/common/directives/click2Top";
// import BackToTop from "vue-backtotop";

import "vue-loading-overlay/dist/vue-loading.css";
import "flatpickr/dist/flatpickr.css";
Vue.use(Loading);
@Component({
  name: "CustomerLayout",
  components: {
    QCustomerFooter,
    QCustomerNav,
    HomeComponent,
    QScripts: () => import("@/common/qScripts.vue"),
  },
  directives: {
    click2Top,
  },
})
export default class CustomerLayout extends Vue {
  get titlePage(): string {
    return this.$route.meta.title || "";
  }
  get subTitle(): string {
    return this.$route.meta.subTitle || "";
  }

  get className(): string {
    return this.$route.meta.className || "";
  }
}
