





































































































































































































































































import {Component, Watch, Vue} from "vue-property-decorator";
import QErrors from "@/common/qErrors.vue";
import { scrollTo } from "seamless-scroll-polyfill";

@Component({
  name: "Home",
  components: {
    QErrors,
  },
})
export default class Home extends Vue {
  @Watch('$route.params')
  changesRoute() {
    this.scrollToHash();
  }

  mounted() {
    this.scrollToHash();
  }

  scrollToHash() {
    const hash = location.hash.toString().slice(1);

    if (hash) {
      const element = this.$refs[hash] as HTMLElement;

      scrollTo(window, { behavior: "smooth", top: element.offsetTop - 50, left: 0 });
    }
  }
}
