import { RouteConfig } from "vue-router";
import AdminLayout from "../views/layouts/admin/qMaster.vue";
import AdminContactList from "@/views/pages/admin/contactList.vue";
import AdminContactDetail from "@/views/pages/admin/contactDetail.vue";
import AdminEstimateList from "../views/pages/admin/estimateList.vue";
import AdminEstimateDetail from "../views/pages/admin/estimateDetail.vue";

const adminRouter: Array<RouteConfig> = [
  {
    path: "/admin",
    name: "admin",
    component: AdminLayout,
    meta: {
      allowRole: ["admin"],
      requiredAuth: true,
    },
    children: [
      {
        path: "default",
        name: "admin.default",
        redirect: "contacts",
      },
      {
        path: "contacts",
        name: "admin.contacts",
        component: AdminContactList,
        meta: {
          pageTitle: "お問い合わせ一覧",
          title: "お問い合わせ一覧",
          subTitle: "。",
          className: "Contact",
          requiredAuth: true,
        },
      },
      {
        path: "contact/:id",
        name: "admin.contacts.detail",
        component: AdminContactDetail,
        meta: {
          pageTitle: "",
          title: "",
          subTitle: "。",
          className: "Contact",
          requiredAuth: true,
        },
      },
      {
        path: "estimates/",
        name: "admin.estimates",
        component: AdminEstimateList,
        meta: {
          pageTitle: "簡単見積もり一覧",
          title: "簡単見積もり一覧",
          subTitle: "。",
          className: "Estimate",
          requiredAuth: true,
        },
      },
      {
        path: "estimate/:id",
        name: "admin.estimates-detail",
        component: AdminEstimateDetail,
        meta: {
          pageTitle: "",
          title: "",
          subTitle: "。",
          className: "Estimate",
          requiredAuth: true,
        },
      },
    ],
  },
];

export default adminRouter;
