






















































import QPaginate from "@/common/qPaginate.vue";
import QSearch from "@/common/qSearch.vue";
import QTableHeader from "@/common/qTableHeader.vue";
import QCalendar from "@/common/qCalendar.vue";
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import DCMtg from "@/model/dCMtg";
import contactApi from "@/api/contactApi";
import QErrors from "@/common/qErrors.vue";
@Component({
  name: "ContactHistory",
  components: {
    QSearch,
    QPaginate,
    QTableHeader,
    QCalendar,
    QErrors,
  },
})
export default class ContactDetail extends Vue {
  public contactId;
  public history = new DCMtg();
  public isShow = false;
  public isEdit = false;
  public errors = {};

  get dateConfig() {
    return {
      enableTime: false,
      dateFormat: "Y-m-d",
      minDate: null,
      maxDate: null,
    }
  };

  get buttonClass() {
    return this.isEdit ? "mb__w-3" : "mb__w-2";
  }
  public show(contactId, history = null) {
    if(history) {
      this.history = new DCMtg(history);
      this.history.mtg_datetime = moment(this.history.mtg_datetime).format('YYYY-MM-DD');
      this.isEdit = true;
    } else {
      this.history = new DCMtg();
      this.isEdit = false;
    }
    this.contactId = contactId;
    this.errors = {};
    this.isShow = true;
  }

  public cancel() {
    this.$modal.showCancel({
      text: this.$t("cancel"),
      onConfirm: () => {
        this.isShow = false;
      },

    });
  }

  public deleteHistory() {
    this.$modal.showDelete({
      text: this.$t("delete"),
      onConfirm: () => {
        this.delete();
      },
    });
  }

  public async delete() {
    const response = await contactApi.deleteHistory(this.history.id);
    this.$emit('refreshData');
    this.isShow = false;
    this.$notify({
      group: 'notify',
      text: response.data.message,
      type: 'success',
    });
  }

  public async updateHistory() {
    const result = await contactApi.updateHistory(this.contactId, this.history);

    if (result.data.status == 'success') {
      this.$emit('refreshData');
      this.isShow = false;
      this.$notify({
        group: 'notify',
        text: result.data.message,
        type: 'success',
      });
    } else {
      this.errors = result.data.message.d_c_mtg[0];
      return;
    }
  }
}
