






































































import { Component, Vue } from "vue-property-decorator";
import Contact, {KUBUN} from "@/model/contact";
import contactApi from "@/api/contactApi";
import QErrors from "@/common/qErrors.vue";

@Component({
  name: "ContactAdd",
  components: {
    QErrors,
  },
})
export default class ContactAdd extends Vue {
  public contact = new Contact();
  public KUBUN = KUBUN;
  public errors ={};
  errorMessage = "";
  public async add() {
    try {
      const result = await contactApi.add(this.contact);
      if (result.data.status != 'success') {
        this.errors = result.data.message;
        return;
      }

      this.$notify({
        group: 'notify',
        text: result.data.message,
        type: 'success',
      });
      this.$router.push({ name: "contact.thanks" });
    } catch (error) {
      console.log(error);
    }
  }
}
