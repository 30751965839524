import DEstimate, {IDEstimate, DEstimateDTO} from "@/model/dEstimate";
import dOriginal, {DOriginalDTO, IDOriginal} from "@/model/dOriginal";

export enum KUBUN {
  SOLE_PROPRIETOR = 0,
  CORPORATION = 1,
}

export const KUBUN_NAMES = {
  [KUBUN.SOLE_PROPRIETOR]: "個人事業主様",
  [KUBUN.CORPORATION]: "法人様",
};

export enum BUSINESS_CATEGORIES {
  RETAIL = 0,
  CONSTRUCTION = 1,
  ESTIMATE = 2,
  SERVICE = 3,
}

export const BUSINESS_CATEGORY_NAMES = {
  [BUSINESS_CATEGORIES.RETAIL]: "卸売・小売業",
  [BUSINESS_CATEGORIES.CONSTRUCTION]: "建設業",
  [BUSINESS_CATEGORIES.ESTIMATE]: "不動産業",
  [BUSINESS_CATEGORIES.SERVICE]: "サービス業",
};

export enum TAXS {
  TAXATION = 0,
  FREE = 1,
}

export const TAX_NAMES = {
  [TAXS.TAXATION]: "課税",
  [TAXS.FREE]: "免税",
};

export enum ANNUAL_SALES {
  LESS_1000 = 0,
  BETWEEN_1000_AND_5000 = 1,
  BETWEEN_5000_AND_1000M = 2,
  BETWEEN_1000M_AND_5000M = 3,
  BETWEEN_5000M_AND_10000M = 4,
  BETWEEN_10000M_AND_50000M = 5,
  OVER_50000M = 6,
  OVER_1000M = 7
}

export const ANNUAL_SALE_NAMES = {
  [KUBUN.SOLE_PROPRIETOR]: {
    [ANNUAL_SALES.LESS_1000]: "〜1,000万円",
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: "1,000万円〜5,000万円",
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: "5,000万円〜1億円",
    [ANNUAL_SALES.OVER_1000M]: "1億円〜",
  },
  [KUBUN.CORPORATION]: {
    [ANNUAL_SALES.LESS_1000]: "〜1,000万円",
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: "1,000万円〜5,000万円",
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: "5,000万円〜1億円",
    [ANNUAL_SALES.BETWEEN_1000M_AND_5000M]: "1億円〜5億円",
    [ANNUAL_SALES.BETWEEN_5000M_AND_10000M]: "5億円〜10億円",
    [ANNUAL_SALES.BETWEEN_10000M_AND_50000M]: "10億円〜50億円",
    [ANNUAL_SALES.OVER_50000M]: "50億円〜",
  }
};

export const ANNUAL_SALE_AMOUNTS = {
  [KUBUN.SOLE_PROPRIETOR]: {
    [ANNUAL_SALES.LESS_1000]: 10000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 20000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 30000,
    [ANNUAL_SALES.OVER_1000M]: 50000,
  },
  [KUBUN.CORPORATION]: {
    [ANNUAL_SALES.LESS_1000]: 20000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 30000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 35000,
    [ANNUAL_SALES.BETWEEN_1000M_AND_5000M]: 50000,
    [ANNUAL_SALES.BETWEEN_5000M_AND_10000M]: 100000,
    [ANNUAL_SALES.BETWEEN_10000M_AND_50000M]: 150000,
    [ANNUAL_SALES.OVER_50000M]: 200000,
  }
};

export const ANNUAL_SALE_FEES = {
  [KUBUN.SOLE_PROPRIETOR]: {
    [ANNUAL_SALES.LESS_1000]: 80000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 120000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 150000,
    [ANNUAL_SALES.OVER_1000M]: 200000,
  },
  [KUBUN.CORPORATION]: {
    [ANNUAL_SALES.LESS_1000]: 120000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 150000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 180000,
    [ANNUAL_SALES.BETWEEN_1000M_AND_5000M]: 200000,
    [ANNUAL_SALES.BETWEEN_5000M_AND_10000M]: 250000,
    [ANNUAL_SALES.BETWEEN_10000M_AND_50000M]: 350000,
    [ANNUAL_SALES.OVER_50000M]: 500000,
  }
};

export const TAX_AMOUNTS = {
  [TAXS.TAXATION]: 0.2,
  [TAXS.FREE]: 0,
};

export const BUSINESS_CATEGORY_AMOUNTS = {
  [BUSINESS_CATEGORIES.RETAIL]: 1.1,
  [BUSINESS_CATEGORIES.CONSTRUCTION]: 1.1,
  [BUSINESS_CATEGORIES.ESTIMATE]: 1,
  [BUSINESS_CATEGORIES.SERVICE]: 1.05,
};

export enum ADVISORS {
  IS_AGREE = 0,
  IS_NOT_AGREE = 1,
}

export const ADVISOR_NAMES = {
  [ADVISORS.IS_AGREE]: "希望する",
  [ADVISORS.IS_NOT_AGREE]: "希望しない（見積もりのみ送信）",
};

export const INCOME_COST_TITLE = {
  [KUBUN.SOLE_PROPRIETOR]: "所得税申告報酬",
  [KUBUN.CORPORATION]: "法人税申告報酬"
}

export const TAX_COSTS = {
  [KUBUN.SOLE_PROPRIETOR]: {
    [ANNUAL_SALES.LESS_1000]: 50000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 80000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 100000,
    [ANNUAL_SALES.OVER_1000M]: 120000,
  },
  [KUBUN.CORPORATION]: {
    [ANNUAL_SALES.LESS_1000]: 50000,
    [ANNUAL_SALES.BETWEEN_1000_AND_5000]: 60000,
    [ANNUAL_SALES.BETWEEN_5000_AND_1000M]: 70000,
    [ANNUAL_SALES.BETWEEN_1000M_AND_5000M]: 80000,
    [ANNUAL_SALES.BETWEEN_5000M_AND_10000M]: 100000,
    [ANNUAL_SALES.BETWEEN_10000M_AND_50000M]: 150000,
    [ANNUAL_SALES.OVER_50000M]: 200000,
  }
};

export const STATUSES = {
  NOT_COMPATIBLE: 0,
  UNDER_NEGOTIATION: 1,
  DURING_CONTRACT: 2,
  CONTRACT_CONCLUSION: 3,
  ACKNOWLEDGED: 4,
};

export const STATUS_NAMES = {
  [STATUSES.NOT_COMPATIBLE]: '未対応',
  [STATUSES.UNDER_NEGOTIATION]: '商談中',
  [STATUSES.DURING_CONTRACT]: '契約手続中',
  [STATUSES.CONTRACT_CONCLUSION]: '契約成立',
  [STATUSES.ACKNOWLEDGED]: '対応済み',
};

export class IEstimate {
  estimate_id: string;
  customer_name: string;
  company_name: string;
  representative_name: string;
  postal_code?: string;
  address_1?: string;
  address_2?: string;
  tel?: string;
  phone?: string;
  mail_address?: string;
  kubun?: number;
  business_category: number;
  tax: number;
  annual_sales: number;
  advisor: string;
  note: string;
  status: string;
  d_estimate: Array<IDEstimate>;
  d_original: Array<IDOriginal>;
}

export class EstimateDTO implements IEstimate {
  estimate_id: string;
  customer_name: string;
  company_name: string;
  representative_name: string;
  postal_code?: string;
  address_1?: string;
  address_2?: string;
  tel?: string;
  phone?: string;
  mail_address?: string;
  kubun?: number;
  business_category: number;
  tax: number;
  annual_sales: number;
  advisor: string;
  note: string;
  status: string;
  d_estimate: Array<DEstimateDTO>;
  d_original: Array<DOriginalDTO>;
}

export default class Estimate extends EstimateDTO {
  constructor(dto?: EstimateDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    const dEstimate = [];
    dEstimate.push(new DEstimate());
    Object.assign(this, {
      kubun: 0,
      business_category: -1,
      tax: 1,
      annual_sales: -1,
      advisor: 0,
      company_name: "",
      customer_name: "",
      mail_address: "",
      address_1: "",
      address_2: "",
      postal_code: "",
      tel: "",
      representative_name: "",
      note: "",
      d_estimate: dEstimate
    });
  }
}
