








import { Component, Prop, Vue } from "vue-property-decorator";
import QErrors from "../../common/qErrors.vue";
import YubinBangoCore from "../../common/scripts/yubinbango-core";

@Component({
  name: "PostalCode",
  components: {
    QErrors
  },
})
export default class PostalCode extends Vue {
  @Prop()
  errors: any;
  @Prop()
  value: string;
  @Prop()
  errorKey: string;
  @Prop({ default: 'mb__button'})
  classes: string;

  isLoading = false;
  isNotFound = false;

  public fullWidthNumConvert(fullWidthNumber) {
    return fullWidthNumber.replace(/[\uFF10-\uFF19]/g, function (m) {
      return String.fromCharCode(m.charCodeAt(0) - 0xFEE0);
    });
  }

  public search() {
    this.isLoading = true;
    this.isNotFound = false;
    try {
      new YubinBangoCore(this.fullWidthNumConvert(this.value), function (addr) {
        if (addr.region) {
          this.$emit('result', {
            pref_name: addr.region,
            city_name: `${addr.locality}${addr.street}`,
            address: addr.extended,
          });
        } else {
          this.isNotFound = true;
        }
      }.bind(this));
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
