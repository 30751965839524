import { render, staticRenderFns } from "./testDialog.vue?vue&type=template&id=6375fb18&"
import script from "./testDialog.vue?vue&type=script&lang=ts&"
export * from "./testDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports