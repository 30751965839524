










import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  name: "QNav",
})
export default class QNav extends Vue {
  @Prop({
    default: "",
  })
  userName;
}
