import { DATE_SLASH_FORMAT } from "@/common/constant";
import moment from "moment";

export const TYPES = {
  ESTIMATE: 0,
  CONTRACT: 1
}

export interface IDEMtg {
  e_mtg_id?: string;
  estimate_id?: string;
  mtg_datetime?: string;
  mtg_content?: string;
  type?: number;
  created?: string;
}

export class DEMtgDTO implements IDEMtg {
  e_mtg_id?: string;
  estimate_id?: string;
  mtg_datetime?: string;
  mtg_contents?: string;
  type?: number;
  created?: string;
}

export default class DEMtg extends DEMtgDTO {

  constructor(dto?: DEMtgDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    Object.assign(this, {
      id: null,
      estimate_id: "",
      mtg_contents: "",
    });
  }

  get mtgDate() {
    if (!this.mtg_datetime) {
      return "";
    }

    return moment(this.mtg_datetime).format(DATE_SLASH_FORMAT);
  }
}
