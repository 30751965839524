


















import { Vue, Component, Watch } from "vue-property-decorator";
import Loading from "vue-loading-overlay";
import QNav from "./qNav.vue";
import QSidebar from "./qSidebar.vue";
import QSidebarAadminCustomer from "./qSidebarAdminCustomer.vue";
import QFooter from "./qFooter.vue";
import store from "@/store";

import "vue-loading-overlay/dist/vue-loading.css";
import "flatpickr/dist/flatpickr.css";
import { namespace } from "vuex-class";
import User from "@/model/user";

const authStore = namespace("Auth");

Vue.use(Loading);
@Component({
  name: "AdminLayout",
  components: {
    QFooter,
    QNav,
    QSidebar,
    QSidebarAadminCustomer,
    QScripts: () => import("@/common/qScripts.vue"),
  },
})
export default class AdminLayout extends Vue {
  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newValue: any, oldValue: any) {
    if (newValue && oldValue && newValue.name !== oldValue.name) {
      this.changedRoute++;
    }
  }
  @authStore.Getter
  public getRole!: string;

  @authStore.Getter
  getUserLogging!: User;

  public changedRoute = 1;

  get titlePage(): string {
    return this.$route.meta.title || "";
  }
  get subTitle(): string {
    return this.$route.meta.subTitle || "";
  }

  importTitle = "";

  userName = "";

  get isLoading(): boolean {
    console.log(store);
    return true;
  }

  get classPage(): string[] {
    this.changedRoute; // hack to re-computed
    const initalClass = ["mb__admin mb__base-color-blue"];
    if (this.$router.currentRoute.meta.className) {
      initalClass.push(this.$router.currentRoute.meta.className);
    }
    return initalClass;
  }
  get currentSiderbarComponent(): string {
    return "QSidebar";
  }
}
