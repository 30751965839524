












// @ is an alias to /src
// import QConfirmDialog from "@/common/qConfirmDialog.vue";
import { Vue, Component } from "vue-property-decorator";
@Component({
  name: "testDialog",
  components: {},
})
export default class TestDialog extends Vue {
  public show(): void {
    const parameters = {
      text: "XXXXXXを削除します。",
      // we are passing callback method for our confirm button
      onConfirm: () => {
        console.log("onConfirm");
      },
      onClose: () => {
        console.log("onClose");
      },
    };
    // now we can call function that will reveal our modal
    this.$modal.showDelete(parameters);
  }
  public showSuccess(): void {
    const parameters = {
      text: "XXXXXXを削除します。",
      // we are passing callback method for our confirm button
      onConfirm: () => {
        console.log("onConfirm");
      },
      onClose: () => {
        console.log("onClose");
      },
    };
    // now we can call function that will reveal our modal
    this.$modal.showCancel(parameters);
  }
}
