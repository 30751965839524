




import { Vue, Component } from "vue-property-decorator";

import EstimateList from "@/components/admin/estimate/estimateList.vue";

@Component({
  name: "AdminEstimate",
  components: {
    EstimateList,
  },
})
export default class AdminEstimateList extends Vue {}
