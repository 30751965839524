import { DATE_SLASH_FORMAT } from "@/common/constant";
import moment from "moment";

export interface IDCMtg {
  id?: string;
  contact_id?: string;
  mtg_datetime?: string;
  mtg_content?: string;
  created?: string;
}

export class DCMtgDTO implements IDCMtg {
  id?: string;
  contact_id?: string;
  mtg_datetime?: string;
  mtg_contents?: string;
  created?: string;
}

export default class DCMtg extends DCMtgDTO {
  original = {};
  constructor(dto?: DCMtgDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    Object.assign(this, {
      id: null,
      contact_id: "",
      mtg_datetime: "",
      mtg_contents: "",
    });
  }

  get mtgDate() {
    if (!this.mtg_datetime) {
      return "";
    }

    return moment(this.mtg_datetime).format(DATE_SLASH_FORMAT);
  }
}
