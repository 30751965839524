export interface IPaginate {
  page: number;
  pageCount: number;
  start: number;
  end: number;
  count: number;
}

export class PaginateDTO implements IPaginate {
  page = 0;
  pageCount = 0;
  start = 0;
  end = 0;
  count = 0;
}

export default class PaginateModel extends PaginateDTO {
  constructor(dto: PaginateDTO) {
    super();
    Object.assign(this, dto);
  }
}
