
































































































import QPaginate from "@/common/qPaginate.vue";
import QSearch from "@/common/qSearch.vue";
import QTableHeader from "@/common/qTableHeader.vue";
import QCalendar from "@/common/qCalendar.vue";
import QErrors from "@/common/qErrors.vue";
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";
import DEMtg from "../../../../model/dEMtg";
import estimateApi from "../../../../api/estimateApi";
@Component({
  name: "mtgCreateOrUpdate",
  components: {
    QSearch,
    QPaginate,
    QTableHeader,
    QCalendar,
    QErrors
  },
})
export default class mtgCreateOrUpdate extends Vue {
  public estimateId;
  public estimateMtg = new DEMtg();
  public isShow = false;
  public isEdit = false;
  public errors = {};
  public isModalContentsDelete = false;
  public isModalContentsCancel = false;

  get dateConfig() {
    return {
      enableTime: false,
      dateFormat: "Y-m-d",
      minDate: null,
      maxDate: null,
    }
  };

  get buttonClass() {
    return this.isEdit ? "mb__w-3" : "mb__w-2";
  }
  public show(estimateId, history = null) {
    if(history) {
      this.estimateMtg = new DEMtg(history);
      this.estimateMtg.mtg_datetime = moment(this.estimateMtg.mtg_datetime).format('YYYY-MM-DD');
      this.isEdit = true;
    } else {
      this.estimateMtg = new DEMtg();
      this.isEdit = false;
    }
    this.estimateId = estimateId;
    this.isShow = true;
  }

  public cancel() {
    this.isModalContentsCancel = true;
  }

  public deleteHistory() {
    this.isModalContentsDelete = true;
  }

  public async createOrUpdateMtg() {
    const response = await estimateApi.createOrUpdateMtg(this.estimateId, this.estimateMtg);

    if (!response.data.status) {
      this.errors = response.data.message;
    } else {
      this.$notify({
        group: 'notify',
        text: response.data.message,
        type: 'success',
      });

      this.errors = {};
      this.estimateMtg = new DEMtg();
      this.$emit('refreshData');
      this.isShow = false;
    }
  }

  public async isYesContentsDelete() {
    const response = await estimateApi.deleteMtg(this.estimateMtg.e_mtg_id);
    this.$notify({
      group: 'notify',
      text: response.data.message,
      type: 'success',
    });
    this.$emit('refreshData');
    this.isShow = false;
    this.isModalContentsDelete = false;
  }

  public isNoContentsDelete() {
    this.isModalContentsDelete = false;
  }

  public isYesContentsCancel() {
    this.isModalContentsCancel = false;
    this.isShow = false;
  }

  public isNoContentsCancel() {
    this.isModalContentsCancel = false;
  }

}
