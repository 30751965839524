




























import {Vue, Component} from "vue-property-decorator";

@Component({
  name: "QCustomerFooter",
  components: {},
})
export default class QCustomerFooter extends Vue {}
