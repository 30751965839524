import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import AuthModule from "./modules/auth";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

function vuexPersistPatch() {
  return (store) => {
    store._vm.$root.$data["vue-persist-patch-delay"] = true;

    store.subscribe((mutation) => {
      if (mutation.type === "RESTORE_MUTATION") {
        store._vm.$root.$data["vue-persist-patch-delay"] = false;
        store._vm.$root.$emit("storageReady");
      }
    });
  };
}

// const vuexLocal = new VuexPersistence({
//   storage: localStorage,
//   modules: ["Auth", "User", "Customer", "OrderHistory", "Item", "Quote"], //only save user module
// });

const vuexLocal = new VuexPersistence({
  storage: localStorage,
  restoreState: (key, storage) => {
    const state = storage.getItem("vuex");
    const data = JSON.parse(state);

    return data;
    // console.log(key, storage.getItem('vuex'));
  },
  // modules: ['Cart'], //only save user module
});

const store = new Vuex.Store({
  modules: {
    Auth: AuthModule,
  },
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
  },
  plugins: [vuexLocal.plugin, vuexPersistPatch()],
  strict: debug,
});

export default store;
