




import { Vue, Component } from "vue-property-decorator";

import ContactAdd from "@/components/customer/contact/contactAdd.vue";

@Component({
  name: "CustomerContactAdd",
  components: {
    ContactAdd,
  },
})
export default class CustomerContactAdd extends Vue {}
