

































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import estimateApi from "@/api/estimateApi";
import Estimate, {
  KUBUN_NAMES,
  BUSINESS_CATEGORY_NAMES,
  TAX_NAMES,
  ANNUAL_SALE_NAMES,
  ADVISORS,
  ADVISOR_NAMES,
  BUSINESS_CATEGORY_AMOUNTS,
  TAX_AMOUNTS,
  ANNUAL_SALE_AMOUNTS,
  ANNUAL_SALE_FEES,
  INCOME_COST_TITLE,
  TAX_COSTS,
  TAXS,
  ANNUAL_SALES,
  KUBUN
} from "@/model/estimate";
import QErrors from "@/common/qErrors.vue";
import DEstimate from "@/model/dEstimate";
import PostalCode from "@/components/common/postalCode.vue";
import _ from 'lodash';
import numeral from 'numeral';

@Component({
  name: "EstimateAdd",
  components: {
    QErrors,
    PostalCode,
  },
})
export default class EstimateAdd extends Vue {
  public estimate = new Estimate();
  public kubun_names = KUBUN_NAMES;
  public tax_names = TAX_NAMES;
  public business_category_names = BUSINESS_CATEGORY_NAMES;
  public annual_sale_names = ANNUAL_SALE_NAMES;
  public advisors = ADVISORS;
  public advisor_names = ADVISOR_NAMES;
  public errors = {};
  public totals = {
    month_cost: 0,
    total_month_cost: 0,
    title_income_cost: INCOME_COST_TITLE[this.estimate.kubun],
    income_cost: 0,
    tax_cost: 0,
    total_cost: 0,
  };
  public dEstimate = new DEstimate();

  @Watch('estimate.kubun', { deep: true})
  changeEstimate() {
    this.estimate.annual_sales = -1;
    this.totals.title_income_cost = INCOME_COST_TITLE[this.estimate.kubun];
  }

  @Watch('estimate.business_category', { deep: true})
  changeBusinessCategory() {
    this.getTotals();
  }

  @Watch('estimate.annual_sales', { deep: true})
  changeAnnualSales() {
    this.getTotals();
  }

  @Watch('estimate.tax', { deep: true})
  changeTax() {
    this.getTotals();
  }

  mounted() {
    this.getTotals();
  }

  public getTotals() {
    const formatPrice = "0,0";
    let businessCateAmount = BUSINESS_CATEGORY_AMOUNTS[this.estimate.business_category] || 0;
    if (this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_5000M_AND_10000M
      || this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_10000M_AND_50000M
      || this.estimate.annual_sales == ANNUAL_SALES.OVER_50000M) {
      businessCateAmount = 1;
    }

    let taxAmount = TAX_AMOUNTS[this.estimate.tax];
    if (this.estimate.kubun == KUBUN.CORPORATION
        && (this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_5000M_AND_10000M
        || this.estimate.annual_sales == ANNUAL_SALES.BETWEEN_10000M_AND_50000M
        || this.estimate.annual_sales == ANNUAL_SALES.OVER_50000M)) {
      taxAmount = 0;
    }

    const annualAmounts = ANNUAL_SALE_AMOUNTS[this.estimate.kubun];
    const annualAmount = annualAmounts[this.estimate.annual_sales] || 0;

    let monthCostFormula = annualAmount * (businessCateAmount + taxAmount);
    let yearCost = monthCostFormula * 12;

    const annualSaleFees = ANNUAL_SALE_FEES[this.estimate.kubun];
    let annualSaleFee = annualSaleFees[this.estimate.annual_sales] || 0;

    const taxCosts = TAX_COSTS[this.estimate.kubun];
    let taxCost = taxCosts[this.estimate.annual_sales] || 0;
    if (this.estimate.tax == TAXS.FREE) {
      taxCost = 0;
    }

    let formulaTotal = yearCost + annualSaleFee + taxCost;

    if (this.estimate.business_category == -1) {
      monthCostFormula = 0;
      yearCost = 0;
      taxCost = 0;
      annualSaleFee = 0;
      formulaTotal = 0;
    }

    this.totals.month_cost = numeral(monthCostFormula).format(formatPrice)
    this.totals.total_month_cost = numeral(yearCost).format(formatPrice);
    this.totals.income_cost = numeral(annualSaleFee).format(formatPrice);
    this.totals.tax_cost = numeral(taxCost).format(formatPrice);
    this.totals.total_cost = numeral(formulaTotal).format(formatPrice);

    this.dEstimate.month_cost = monthCostFormula;
    this.dEstimate.year_cost = monthCostFormula * 12;
    this.dEstimate.tax_cost = taxCost;
    this.dEstimate.income_cost = annualSaleFee;
    this.dEstimate.total = formulaTotal;
    this.dEstimate.consumption_tax = formulaTotal / 10;
    this.dEstimate.total_all = formulaTotal + (formulaTotal / 10);
  }

  public async submit() {
    this.estimate.d_estimate[0] = this.dEstimate;
    const response = await estimateApi.add(this.estimate);
    if (!response.data.status) {
      this.errors = response.data.message;
    } else {
      this.$notify({
        group: 'notify',
        text: response.data.message,
        type: 'success',
      });
      // Redirect to estimate/thanks
      this.$router.push({'name': 'estimate.thanks'});
    }

  }

  public onChangePostalCode(address) {
    this.estimate.address_1 = address.pref_name + " " + address.city_name;
  }
}
