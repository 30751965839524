








import { Vue, Component } from "vue-property-decorator";
import QCalendar from "@/common/qCalendar.vue";
@Component({
  name: "TestCalendar",
  components: {
    QCalendar,
  },
})
export default class TestCalendar extends Vue {
  currentDate = "2020-10-16";
  public config = {};
}
