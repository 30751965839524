











// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import QNotify from "@/common/qNotify";
@Component({
  name: "testPNotify",
  components: {},
})
export default class TestPNotify extends Vue {
  public show(): void {
    QNotify.success({
      text: "Simple Notify",
    });
  }
  public showError(): void {
    QNotify.error({
      text: "Simple Notify",
    });
  }
}
