import { AxiosResponse } from "axios";
import api from "./api";

interface AuthResponse {
  success: string;
  data: {
    authenticate_status: string;
    role: string;
    expired_at: string;
  };
}
interface AuthRequest {
  username: string;
  password: string;
}

class UserApi extends api {
  constructor() {
    super();
    this.setup();
  }

  async login(data: AuthRequest): Promise<AxiosResponse> {
    return this.getHttp().post<AuthResponse>("/auth/login", data);
  }
  async refreshToken(): Promise<AxiosResponse> {
    return this.getHttp().get<AuthResponse>("/auth/refresh-token");
  }
  async logout(): Promise<AxiosResponse> {
    return this.getHttp().get<AuthResponse>("/auth/logout");
  }
  async delete(id): Promise<AxiosResponse> {
    return this.getHttp().delete<AuthResponse>("/admin/users/" + id);
  }
}
export default new UserApi();
