

















































































import QPaginate from "@/common/qPaginate.vue";
import QSearch from "@/common/qSearch.vue";
import QTableHeader from "@/common/qTableHeader.vue";
import QMultiSelect from "@/common/qMultiSelect.vue";
import { Component, Vue } from "vue-property-decorator";
import PaginateModel from "@/model/paginate";
import estimateApi from "@/api/estimateApi";
import Estimate, {BUSINESS_CATEGORY_NAMES, KUBUN_NAMES, STATUS_NAMES, TAX_NAMES} from "@/model/estimate";
import _ from 'lodash';

@Component({
  name: "Admin",
  components: {
    QSearch,
    QPaginate,
    QTableHeader,
    QMultiSelect
  },
})
export default class EstimateList extends Vue {
  public estimates: Estimate[] = null;
  public keyword: string = null;
  public search = this.searchInital();
  public statusNames = STATUS_NAMES;
  public kubunNames = KUBUN_NAMES;
  public taxNames = TAX_NAMES;
  public businessCategoryNames = BUSINESS_CATEGORY_NAMES;

  public headers = [
    { id: "created", name: "日時",order: "desc" },
    { id: "estimate_no", name: "NO" },
    { id: "status", name: "ステータス" },
    { id: "customer_name", name: "お客様名" },
    { id: "kubun", name: "区分" },
    { id: "business_category", name: "業種" },
    { id: "tax", name: "消費税" },
    { id: "annual_sales", name: "年商" },
    { id: "advisor", name: "顧問依頼" },
    { id: "mail_address", name: "メールアドレス" },
    { id: "", name: "" },
  ];

  public pagination = new PaginateModel({
    page: 0,
    pageCount: 0,
    start: 0,
    end: 0,
    count: 0,
  });

  public searchInital() {
    return {
      keyword: null,
      statuses: [],
      kubuns: [],
      business_categories: [],
      taxs: [],
      sort: "created",
      direction: "desc",
      page: "1",
    };
  }

  get getPaginate() {
    return this.pagination;
  }

  mounted() {
    this.initalSearch(null);
    this.getEstimateList();
  }

  public clear() {
    this.search = this.searchInital();
    this.changeState();
    this.getEstimateList();
  }

  public doSearch(query: any): void {
    this.search = { ...this.search, ...query };
    this.getEstimateList();
  }

  public onSearch(): void {
    this.changeState();
    this.getEstimateList();
  }

  public changeState(): void {
    let { name, params, query } = this.$router.currentRoute;

    this.search.page = "1";
    query = { ...query, ...this.search };

    this.$router
      .replace({ name: name, params: params, query })
      .catch((error) => {
        console.error(error);
      });
  }

  public initalSearch(parameters: any): void {
    const query = parameters || this.$router.currentRoute.query;
    if (this.isNotEmpty(query.statuses)) {
      query.statuses = [query.statuses];
    }
    if (this.isNotEmpty(query.kubuns)) {
      query.kubuns = [query.kubuns];
    }
    if (this.isNotEmpty(query.business_categories)) {
      query.business_categories = [query.business_categories];
    }

    if (this.isNotEmpty(query.taxs)) {
      query.taxs = [query.taxs];
    }
    this.search = {
      ...this.search,
      ...query,
    };
  }

  public isNotEmpty(value) {
    return !_.isNil(value) && !_.isArray(value);
  }

  public async getEstimateList() {
    const result = await estimateApi.list(this.search);
    this.pagination = result.paginate;
    this.estimates = result.list;
  }
}
