import { DATE_TIMES_SLASH_FORMAT, DATE_SLASH_FORMAT } from "@/common/constant";
import moment from "moment";
import DCMtg from "./dCMtg";

export enum KUBUN {
  SOLE_PROPRIETOR = 0,
  CORPORATION= 1,
}

export const KUBUN_NAME = {
  [KUBUN.SOLE_PROPRIETOR]: "個人事業主",
  [KUBUN.CORPORATION]: "法人",
}

export interface IContact {
  id?: string;
  kubun?: number;
  company_name?: string;
  customer_name?: string;
  mail_address?: string;
  tel?: string;
  contact_detail?: string;
  created?: string;
  d_c_mtg?: DCMtg[];
}

export class ContactDTO implements IContact {
  id?: string;
  kubun?: number;
  company_name?: string;
  customer_name?: string;
  mail_address?: string;
  tel?: string;
  contact_detail?: string;
  created?: string;
  d_c_mtg?: DCMtg[];
}

export default class Contact extends ContactDTO {
  original = {};
  constructor(dto?: ContactDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    Object.assign(this, {
      id: null,
      kubun: 1,
      company_name: "",
      customer_name: "",
      mail_address: "",
      tel: "",
      contact_detail: "",
      d_c_mtg: [],
    });
  }

  get kubunName() {
    return KUBUN_NAME[Number(this.kubun)];
  }


  get createdFormatDateTime() {
    if (!this.created) {
      return ""
    }
    return moment(this.created).format(DATE_TIMES_SLASH_FORMAT);
  }

  get createdFormatDate() {
    if (!this.created) {
      return ""
    }
    return moment(this.created).format(DATE_SLASH_FORMAT);
  }

  public formatSlashDate(date) {
    return this.formatDateTime(date, DATE_SLASH_FORMAT);
  }

  public formatSlashDateTime(date) {
    return this.formatDateTime(date, DATE_TIMES_SLASH_FORMAT);
  }

  public formatDateTime(date, format) {
    if (!date) {
      return ""
    }
    return moment(date).format(format);
  }
}
