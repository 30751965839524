



































import { Component, Vue } from "vue-property-decorator";
import User from "@/model/user";

@Component({
  name: "QSidebarAadminCustomer",
})
export default class QSidebarAadminCustomer extends Vue {
  User = new User();
  public logout() {
    try {
      this.User.logout();
    } catch (error) {
      console.log(error);
    }
  }
}
