import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import adminRouter from "@/router/admin";
import customerRouter from "@/router/customer";
import testRoute from "@/router/test";
Vue.use(VueRouter);
declare global {
  interface Window {
    user: any;
  }
}

const baseRouter: Array<RouteConfig> = [
  {
    path: "/",
    name: "main",
    redirect: () => {
      return { name: "customer.home" };
    },
  },
  {
    path: "/admin",
    name: "admin",
    redirect: () => {
      return { name: "admin.contacts" };
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/pages/logout.vue"),
  },
  {
    path: "*",
    name: "error",
    component: () => import("../views/pages/error.vue"),
  },
];

const routes = [
  ...baseRouter,
  ...adminRouter,
  ...customerRouter,
  ...testRoute,
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.NODE_ENV === "development" ? process.env.BASE_URL : "",
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiredAuth && !window.user) {
    window.location.href = '/login?r=' + to.path;
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  document.body.classList.remove('open');
  Vue.nextTick(() => {
    document.title = to.meta.pageTitle || "";
  });
});
export default router;
