import PaginateModel from "@/model/paginate";
import Contact, { ContactDTO } from "@/model/contact";
import DCMtg from "@/model/dCMtg";
import { AxiosResponse } from "axios";
import api from "./api";

interface RequestInterface {
  data: ContactDTO[];
  paginate: any;
}

interface ContactResponse {
  success: string;
  data: {
    authenticate_status: string;
    role: string;
    expired_at: string;
  };
}
interface ContactList {
  list: Contact[];
  paginate: any;
}

class ContactApi extends api {
  constructor() {
    super();
    this.setup();
  }

  async list(query: any): Promise<ContactList> {
    const response = await this.getHttp().get<RequestInterface>(
      "/contact/list",
      {
        params: query,
      }
    );

    const list: Contact[] = [];
    response.data.data.map((ContactDto) => list.push(new Contact(ContactDto)));
    const paginate = new PaginateModel(response.data.paginate);
    return {
      list: list,
      paginate: paginate,
    };
  }

  async detail(id: string): Promise<AxiosResponse> {
    const response = await this.getHttp().get<any>(
      "/contact/detail/" + id
    );

    if (response.status === 200) {
      return response.data;
    }
  }

  async add(data: Contact): Promise<AxiosResponse> {
    return this.getHttp().post<ContactResponse>("/contact/create", data);
  }

  async updateHistory(contactId, data: DCMtg): Promise<AxiosResponse> {
    return this.getHttp().post<ContactResponse>(
      "/contact/update-history/" + contactId,
      data
    );
  }
  async deleteHistory(historyId): Promise<AxiosResponse> {
    return this.getHttp().delete<ContactResponse>(
      "/contact/delete-history/" + historyId
    );
  }
}
export default new ContactApi();
