




import { Vue, Component } from "vue-property-decorator";

import ContactThank from "@/components/customer/contact/contactThank.vue";

@Component({
  name: "CustomerContactThanks",
  components: {
    ContactThank,
  },
})
export default class CustomerContactThank extends Vue {}
