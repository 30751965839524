









































import { Component, Vue } from "vue-property-decorator";
import DEMtg, { TYPES } from "@/model/dEMtg";
import estimateApi from "@/api/estimateApi";
import QErrors from "@/common/qErrors.vue";
@Component({
  name: "contract",
  components: {
    QErrors
  },
})
export default class Contract extends Vue {
  public estimateId;
  public estimateMtg = new DEMtg();
  public isShow = false;
  public isEdit = false;
  public errors = {};

  public show(estimateId) {
    this.estimateId = estimateId;
    this.isShow = true;
  }

  public resetData() {
    this.errors = {};
    this.estimateMtg = new DEMtg();
  }

  public cancel() {
    this.isShow = false;
    this.resetData();
  }

  public async download() {
    this.estimateMtg.type = TYPES.CONTRACT;
    const response = await estimateApi.downloadEstimate(this.estimateId, this.estimateMtg);

    if (!response.data.status) {
      this.errors = response.data.message;
    } else {
      this.resetData();
      this.isShow = false;

      // Set url pdf
      if (response.data.url.length > 0) {
        window.open(response.data.url, '_blank');
      }

    }
  }
}
