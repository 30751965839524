import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import router from "@/router";
import User from "@/model/user";

@Module({ namespaced: true })
export default class AuthModule extends VuexModule {
  authenticate_status = "";
  expired_at = "";
  role = "";
  userLogging: User;
  @Mutation
  setAuthenticateStatus(status: string): void {
    this.authenticate_status = status;
  }
  @Mutation
  setExpiredAt(time: string): void {
    this.expired_at = time;
  }
  @Mutation
  setRole(role: string): void {
    this.role = role;
  }
  @Mutation
  setUserLogging(user: User): void {
    this.userLogging = user;
  }
  @Action({ rawError: true })
  updateAuthenticateStatus(status: string): void {
    this.context.commit("setAuthenticateStatus", status);
  }
  @Action
  updateExpiredAt(time: string): void {
    this.context.commit("setExpiredAt", time);
  }
  @Action
  updateRole(role: string): void {
    this.context.commit("setRole", role);
  }

  @Action
  logout(): void {
    this.context.commit("setToken", "");
    this.context.commit("setExpiredAt", "");
    this.context.commit("setRole", "");
    // this.context.commit("setUserInfor", "");
    router.push({ name: "login" });
  }

  get getAuthenticateStatus(): string {
    return this.authenticate_status;
  }
  get getExpiredAt(): string {
    return this.expired_at;
  }
  get getRole(): string {
    return this.role;
  }

  get getUserLogging(): User {
    return this.userLogging;
  }
}

// export default getModule(AuthModule);
