










// we must import our Modal plugin instance
// because it contains reference to our Eventbus
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";
@Component({
  name: "qError",
})
export default class qError extends Vue {
  @Prop({
    default: () => {
      return {};
    },
  })
  errors;
  @Prop() name: string;
  @Prop({
    default: () => {
      return {};
    },
  })
  errorStyle;
  @Prop({
    default: () => {
      return ''
    },
  })
  unsetText;

  get totalMssages() {
    return Object.keys(this.messages).length;
  }

  get messages(): any {
    if (_.isEmpty(this.errors)) {
      return {};
    }
    if (this.name) {
      const current = this.getErrorMessages(this.name);

      if (this.unsetText.length > 0) {
        const dependError = this.getErrorMessages(this.unsetText);
        if (Object.keys(dependError).length > 0) {
          return {};
        }
      }

      return current;
    } else if (!(this.name in this.errors)) {
      return {};
    } else {
      return this.flatten(this.errors);
    }
  }

  getErrorMessages(name) {
    const keys = name.split(".");
    let current = this.errors;
    for (let value of keys) {
      current = current[value] || [];
    }

    return current;
  }

  traverseAndFlatten(currentNode, target, flattenedKey) {
    for (const key in currentNode) {
      if (key in currentNode) {
        const newKey = flattenedKey === null ? key : flattenedKey + "." + key;
        let value = currentNode[key];
        if (typeof value === "object") {
          this.traverseAndFlatten(value, target, newKey);
        } else {
          target[newKey] = value;
        }
      }
    }
  }

  flatten(object) {
    var flattenedObject = {};
    this.traverseAndFlatten(object, flattenedObject, null);
    return flattenedObject;
  }

  get getStyle(): any {
    return this.errorStyle;
  }
}
