




















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "qSearch",
})
export default class QSearch extends Vue {
  public q: string | string[] = "";

  public mounted(): void {
    this.q = this.$router.currentRoute.query.q || "";
    this.$emit("search", this.$router.currentRoute.query);
  }

  public clear(): void {
    this.q = "";
    this.doSearch();
  }

  public doSearch(): void {
    const { name, params, query } = this.$router.currentRoute;

    const currentQuery = { ...query, q: this.q, page: "" };

    this.$emit("search", currentQuery);

    this.$router
      .replace({ name: name, params: params, query: currentQuery })
      .catch((error) => {
        console.error(error);
      });
  }
}
