
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Paginate from "vuejs-paginate";
import PaginateModel from "@/model/paginate";
import click2Top from "@/common/directives/click2Top";
@Component({
  name: "qPaginate",
  components: {
    Paginate,
  },
  directives: {
    click2Top,
  },
})
export default class QPaginate extends Vue {
  @Prop({
    default: () => {
      return {
        page: 1,
        pageCount: 1,
      };
    },
  })
  paginate!: PaginateModel;
  public page = 0;

  public mounted(): void {
    this.page = Number.parseInt(this.$router.currentRoute.query.page + "") || 0;
  }

  public changePage(page: any): void {
    let { name, params, query } = this.$router.currentRoute;

    query = { ...query, page };

    this.$emit("page-change", query);

    this.$router
      .replace({ name: name, params: params, query })
      .catch((error) => {
        console.error(error);
      });
  }

  get pagination(): PaginateModel {
    return (
      this.paginate ||
      new PaginateModel({
        page: 0,
        pageCount: 0,
        start: 0,
        end: 0,
        count: 0,
      })
    );
  }

  @Watch("paginate")
  paginateChanged(newValue: PaginateModel): void {
    console.log(newValue);
    this.page !== newValue.page && (this.page = newValue.page);
  }
}
