export interface IDOriginal {
  estimate_detail_id?: string;
  estimate_id?: string;
  title: string;
  quantity: number;
  unit: string;
  unit_price: number;
  total: number;
  created?: string;
  modified?: string;
  deleted?: string;
}

export class DOriginalDTO implements IDOriginal {
  estimate_detail_id?: string;
  estimate_id?: string;
  title: string;
  quantity: number;
  unit: string;
  unit_price: number;
  total: number;
  created?: string;
  modified?: string;
  deleted?: string;
}

export default class DOriginal extends DOriginalDTO {

  constructor(dto?: DOriginalDTO) {
    super();
    if (dto) {
      Object.assign(this, dto);
    } else {
      this.new();
    }
  }

  public new() {
    Object.assign(this, {
      estimate_id: "",
      title: '',
      unit: '',
    });
  }
}
