



















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "EstimateThank",
  components: {},
})
export default class EstimateThank extends Vue {}
