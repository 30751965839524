




import { Vue, Component } from "vue-property-decorator";

import EstimateThank from "@/components/customer/estimate/estimateThank.vue";

@Component({
  name: "CustomerEstimateAdd",
  components: {
    EstimateThank,
  },
})
export default class CustomerEstimateThank extends Vue {}
