export interface ICustomer {
  id?: number;
  customer_code?: string;
  customer_name: string;
  representative_name: string;
  industry_code: string;
}

export class CustomerDTO implements ICustomer {
  id?: number;
  customer_code = "";
  customer_name = "";
  representative_name = "";
  industry_code = "";
}

export class SystemPrefecture {
  delivery_fee: number;
  less_than_price: number;
  prefecture_code: number;
  prefecture_name: string;
}

export class CustomerDeliveryAddress {
  id?: number;
  customer_code: string;
  name: string;
  postal_code: string;
  prefecture_code: number;
  address_1: string;
  address_2: string;
  tel_number: string;
  fax_number: string;
  supervisor_name: string;
  supervisor_f: string;
  busyo_name: string;
  is_enabled: boolean;
  system_prefecture?: SystemPrefecture;
  constructor(dto: Record<string, unknown>) {
    Object.assign(this, dto);
  }
}

export default class Customer extends CustomerDTO {
  constructor(dto: CustomerDTO) {
    super();
    Object.assign(this, dto);
  }
}
