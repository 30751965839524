function validate(binding) {
  if (typeof binding.value !== 'function') {
    console.warn('[Vue-click-outside:] provided expression', binding.expression, 'is not a function.')
    return false
  }

  return true
}

function isPopup(popupItem, elements) {
  if (!popupItem || !elements)
    return false

  for (let index = 0, length = elements.length; index < length; index++) {
    try {
      if (popupItem.contains(elements[index])) {
        return true
      }
      if (elements[index].contains(popupItem)) {
        return false
      }
    } catch(error) {
      console.log(error);
      return false
    }
  }

  return false
}

function isServer(vNode) {
  return typeof vNode.componentInstance !== 'undefined' && vNode.componentInstance.$isServer
}

export default {
  bind: function (element, binding, vNode) {
    if (!validate(binding)) return

    // Define Handler and cache it on the element
    function handler(event) {
      if (!vNode.context) return

      // some components may have related popup item, on which we shall prevent the click outside event handler.
      const elements = event.path || (event.composedPath && event.composedPath())
      elements && elements.length > 0 && elements.unshift(event.target)

      if (element.contains(event.target) || isPopup(vNode.context.popupItem, elements)) return

      element.__vueClickOutside__.callback(event)
    }

    // add Event Listeners
    element.__vueClickOutside__ = {
      handler: handler,
      callback: binding.value
    }
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    !isServer(vNode) && document.addEventListener(clickHandler, handler)
  },
  update: function (element, binding) {
    if (validate(binding)) element.__vueClickOutside__.callback = binding.value
  },
  unbind: function (element, binding, vNode) {
    // Remove Event Listeners
    const clickHandler = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
    !isServer(vNode) && element.__vueClickOutside__ && document.removeEventListener(clickHandler, element.__vueClickOutside__.handler)
    delete element.__vueClickOutside__
  }
}
