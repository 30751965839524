










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import flatpickr from "flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

import $ from "jquery";
flatpickr.localize(Japanese);
flatpickr.l10ns.default.firstDayOfWeek = 0;
@Component({
  name: "qCalendar",
  components: {},
})
export default class QCalendar extends Vue {
  datepicker;
  @Prop() value;
  @Prop({
    type: Object,
    default() {
      return {};
    },
  })
  config;
  mounted(): void {
    this.config.defaultDate = this.value;
    this.datepicker = flatpickr($(this.$refs.qcal), this.config);
  }

  beforeUpdate() {
    this.datepicker.setDate(this.value);
  }

  destroy() {
    this.datepicker.destroy();
  }

  @Watch("config", { deep: true })
  changeConfig() {
    for (const configKey of Object.keys(this.config)) {
      this.datepicker.set(configKey, this.config[configKey]);
    }
  }
}
