import { RouteConfig } from "vue-router";

import CustomerLayout from "@/views/layouts/customer/qMaster.vue";
import CustomerContactAdd from "@/views/pages/customer/contactAdd.vue";
import CustomerContactThank from "@/views/pages/customer/contactThank.vue";
import CustomerEstimateAdd from "@/views/pages/customer/estimateAdd.vue";
import CustomerEstimateThank from "@/views/pages/customer/estimateThank.vue";
import CustomerHome from "@/views/pages/home.vue";
const customerRouter: Array<RouteConfig> = [
  {
    path: "/",
    name: "customer",
    component: CustomerLayout,
    meta: {
      allowRole: ["customer"],
    },
    children: [
      {
        path: "default",
        name: "default",
        redirect: "customer.home",
      },
      {
        path: "/",
        name: "customer.home",
        component: CustomerHome,
        meta: {
          className: "Home",
        }
      },
      {
        path: "contact",
        name: "contact.add",
        component: CustomerContactAdd,
        meta: {
          pageTitle: "お問い合わせ",
          title: "お問い合わせ",
          subTitle: "。",
          className: "Contact",
        },
      },
      {
        path: "contact/thanks",
        name: "contact.thanks",
        component: CustomerContactThank,
        meta: {
          pageTitle: "お問い合わせ完了",
          title: "お問い合わせ完了",
          subTitle: "。",
          className: "Contact",
        },
      },
      {
        path: "estimate",
        name: "estimate.add",
        component: CustomerEstimateAdd,
        meta: {
          pageTitle: "簡単お見積もり",
          title: "簡単お見積もり",
          subTitle: "。",
          className: "Estimate",
        },
      },
      {
        path: "estimate/thanks",
        name: "estimate.thanks",
        component: CustomerEstimateThank,
        meta: {
          pageTitle: "簡単お見積もり完了",
          title: "簡単お見積もり完了",
          subTitle: "。",
          className: "Estimate",
        },
      },
    ],
  },
];

export default customerRouter;
