















import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ContactThank",
  components: {},
})
export default class ContactThank extends Vue {}
